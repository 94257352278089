<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import AuthService from "@/api/services/auth.service.js";
import DatafileService from "@/api/services/datafile.service";
import WorksheetService from "@/api/services/worksheet.service";
import appConfig from "@/app.config";
import ApplyEditsModal from "@/redem/datafiles/components/modals/apply-edits-modals";
import BackConfirmModal from "@/redem/datafiles/components/modals/back-confirm-modal.vue";
import CleanDataModal from "@/redem/datafiles/components/modals/clean-data-modal.vue";
import ExportWorksheetModal from "@/redem/datafiles/components/modals/export-worksheet-modal.vue";
import ManageColumnsModal from "@/redem/datafiles/components/modals/manage-columns-modal";
import OESFeedbackModal from "@/redem/datafiles/components/modals/OES-suggest-score-modal.vue";
import QuotaSettingsModal from "@/redem/datafiles/components/modals/quota-settings-modal.vue";
// Modals
import DeleteSelectedRespondentsModal from "@/redem/datafiles/components/modals/delete-selected-respondents-modal.vue";
import EditCleaningSettingsModal from "@/redem/datafiles/components/modals/edit-cleaning-settings-modal.vue";
import RespondentModal from "@/redem/datafiles/components/modals/respondent-modal/index.vue";
import UpdateDatafileModal from "@/redem/datafiles/components/modals/update-datafile-modal.vue";
import WeightDataModal from "@/redem/datafiles/components/modals/weight-data-modal.vue";
import CategoryTemplate from "@/redem/datafiles/components/templates-for-worksheets/categoryTemplate.vue";
import infoTemplate from "@/redem/datafiles/components/templates-for-worksheets/infoTemplate.vue";
import isExcludedTemplate from "@/redem/datafiles/components/templates-for-worksheets/isExcludedTemplate.vue";
import isMarkedTemplate from "@/redem/datafiles/components/templates-for-worksheets/isMarkedTemplate.vue";
import isSelectTemplate from "@/redem/datafiles/components/templates-for-worksheets/isSelectTemplete.vue";
import selectAllTemplate from "@/redem/datafiles/components/templates-for-worksheets/selectAllTemplate.vue";
import Layout from "@/router/layouts/worksheet-layout.vue";
import DataCleaningMethod from "@/shared/enums/dataCleaningMethods.js";
import DatapointProperty from "@/shared/enums/datapointProperties.js";
import { EventBus } from "@/shared/util/event-bus.js";

// Helpers
import {
checkDatafileSize,
decodeKey,
exportWorksheet,
initializeSocketBufferTimer,
isEmptyString,
mapOESNamesToCodes
} from "@/shared/util/helpers.js";
import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
// Const
import {
MAX_REQUEST_TIME,
WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import cookie from "vue-cookies";

/**
 * Datafile Result
 */
export default {
  page: {
    title: "Work Sheet",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    VGrid,
    ManageColumnsModal,
    ApplyEditsModal,
    RespondentModal,
    BackConfirmModal,
    CleanDataModal,
    WeightDataModal,
    ExportWorksheetModal,
    UpdateDatafileModal,
    QuotaSettingsModal,
    OESFeedbackModal,
    DeleteSelectedRespondentsModal,
    EditCleaningSettingsModal
  },
  data() {
    return {
      /*******  Data varibales ********/
      columns: [],
      rows: [],
      allColumns: [],
      filteredRows: [],
      editedCells: [],
      datafileId: this.$route.query.datafileId,
      dbDatafile: {},
      metadata: {},
      datafileTitle: "",
      datafileKey: null,
      columnTypes: null,
      weightingSettings: {},
      cleaningSettings: {},
      cleaningMethod: null,
      markThreshold: null,
      numberOfIncludedDataRecords: 0,
      numberOfRecords: 0,
      selectedDataRecordForFeedback: null,
      questionTitleForFeedback: null,
      dataPointIdentifierForFeedback: null,
      loggedInUser: null,
      selectedRespondentsForDelete: [],
      selectAllToDelete: false,
      /******** Operational Variables *********/
      displayDeleteRespondentButton: false,
      isLoading: true,
      showConfirmDialog: false,
      showManageColumnsModal: false,
      showApplyEditsModal: false,
      showRespondentModal: false,
      showDetailsForRecord: null,
      includeIBS: false,
      includeTimestamp: false,
      includeCS: false,
      includePS: false,
      isDisplayCleanDataModal: false,
      isDisplayWeightDataModal: false,
      isDisplayExportWorksheetModal: false,
      mountModals: false,
      isDisplayUpdateDatafileModal: false,
      isDisplayQuotaSettingsModal: false,
      exportWorksheet,
      isLiveCleaning: "",
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayOESFeedbackModal: false,
      showOESTab: false,
      activatedtabInRespondentModal: 0,
      openOESFeedbackModalFromRespondentModal: false,
      isDisplayDeleteRespondentModal: false,
      settings: {
        sortSettings: [],
        filterSettings: []
      }
    };
  },
  async created() {
    EventBus.$on(
      "showOESFeedbackModal",
      async (dataPointIdentifierFor, questionTitle, selectedDataRecord) => {
        this.dataPointIdentifierForFeedback = dataPointIdentifierFor;
        this.questionTitleForFeedback = questionTitle;
        this.selectedDataRecordForFeedback = selectedDataRecord;
        this.openOESFeedbackModalFromRespondentModal = true;

        this.showRespondentModal = false;
        this.isDisplayOESFeedbackModal = true;
      }
    );
    EventBus.$on("clickOnInfo", (params) => {
      this.showRespondentModal = true;
      this.showDetailsForRecord = String(params.recordId);
    });
    EventBus.$on("manuallyExcluded", (recordRow) => {
      for (const row of this.rows) {
        if (
          recordRow[this.recordIdColumnName] === row[this.recordIdColumnName]
        ) {
          row["manualInclude"] = false;
          row["manualExclude"] = true;
          row["redem_CleaningTime"] = new Date();
        }
      }
      this.numberOfIncludedDataRecords--;
    });
    EventBus.$on("manuallyIncluded", (recordRow) => {
      for (const row of this.rows) {
        if (
          recordRow[this.recordIdColumnName] === row[this.recordIdColumnName]
        ) {
          row["manualInclude"] = true;
          row["manualExclude"] = false;
          row["redem_CleaningTime"] = new Date();
        }
      }
      this.numberOfIncludedDataRecords++;
    });
    EventBus.$on("selected", (recordRow) => {
      this.selectedRespondentsForDelete.push(
        recordRow[this.recordIdColumnName]
      );
      this.displayDeleteRespondentButton = true;
    });
    EventBus.$on("unSelected", (recordRow) => {
      let indextToRemove = this.selectedRespondentsForDelete.indexOf(
        recordRow[this.recordIdColumnName]
      );
      this.selectedRespondentsForDelete.splice(indextToRemove, 1);
      this.selectAllToDelete = false;

      EventBus.$emit("removeSelectAll");

      if (this.selectedRespondentsForDelete.length === 0)
        this.displayDeleteRespondentButton = false;
    });
    EventBus.$on("selectedAll", () => {
      this.selectedRespondentsForDelete = [];
      for (let i = 0; i < this.filteredRows.length; i++) {
        this.filteredRows[i].select = true;
        this.selectedRespondentsForDelete.push(
          this.filteredRows[i][this.recordIdColumnName]
        );
      }
      this.selectAllToDelete = true;
      this.displayDeleteRespondentButton = true;
    });
    EventBus.$on("unSelectedAll", () => {
      this.selectedRespondentsForDelete = [];
      for (let i = 0; i < this.filteredRows.length; i++) {
        this.filteredRows[i].select = false;
      }
      this.selectAllToDelete = false;
      this.displayDeleteRespondentButton = false;
    });
    EventBus.$on("showDuplicatedRespondent", (respondentId) => {
      this.openRespondentModal(respondentId.trim());
    });
  },
  async mounted() {
    await this.getDatafile(this.datafileId);
    if (this.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }
    await this.getDataForTable(this.datafileId);

    // getLoggedInUser
    this.loggedInUser = await AuthService.getLoggedInUser();

    this.editedCells = [];
  },
  beforeDestroy() {
    if (this.socket)
      this.socket.close(1000, String(this.datafileId) + ";WORKSHEET");
    this.socket = null;
  },
  beforeRouteLeave(to, from, next) {
    if (this.to) {
      next();
    } else {
      this.to = to;
      this.showConfirmDialog = true;
    }
  },
  methods: {
    initializeWebSocketSubscription() {
      const cleanedValues = false;
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async (e) => {
        await checkDatafileSize(this, cleanedValues);
        socket.send(this.datafileId + ";WORKSHEET;" + token);
      };
      socket.onmessage = (event) => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then((datafile) => {
            this.dbDatafile = datafile;
          });
        }
        if (this.websocketBuffer < 1) {
          const t1 = new Date();
          this.getDataForTable(this.datafileId).then(() => {
            const t2 = new Date();
            const dT = t2 - t1;
            if (dT > this.maxRequestTime && this.websocketBuffer < 2) {
              const ADD_TO_BUFFER = 2;
              // console.log(`[work-sheet.vue]: Backend server is responding very slowly. Pausing requests for ${ADD_TO_BUFFER * (this.websocketUpdateInterval / 1000)} seconds.`);
              this.websocketUpdateInterval += 500;
              // console.log(`[work-sheet.vue]: Increasing update interval to ${this.websocketUpdateInterval} ms`)
              this.websocketBuffer += ADD_TO_BUFFER;
            }
            // console.log("[work-sheet.vue]: deltaT: ", dT);
          });
          this.websocketBuffer++;
        }
      };
    },
    closeDialog() {
      this.showConfirmDialog = false;
      this.to = null;
    },
    discardChanges() {
      this.showConfirmDialog = false;
      this.$router.push(this.to);
    },
    async getDatafile(datafileID) {
      const datafile = await DatafileService.getDatafile(datafileID);
      this.dbDatafile = datafile;
      this.datafileTitle = datafile.title;
      this.datafileKey = datafile.datafileKey;
      this.isLiveCleaning = datafile.isLiveCleaning;
      this.metadata = datafile.metadata;
      this.numberOfRecords = datafile.numberOfRecords;
      this.weightingSettings = datafile.weightingMethod;
      this.cleaningSettings = datafile.cleaningSettings;
      this.cleaningMethod = datafile.cleaningMethod;
      this.markThreshold = datafile.markThreshold;
      this.includeTimestamp = datafile.includeTimestamp;
      this.includePS = datafile.includePS;
      this.includeCS = datafile.includeCS;
      this.includeIBS = datafile.includeItemBattery;
      this.includeOES = datafile.includeOES;
      if (!this.cleaningSettings)
        this.cleaningSettings = {
          scoreThreshold: null,
          targetSampleSize: null
        };
      if (!this.cleaningMethod)
        this.cleaningMethod = DataCleaningMethod.TARGET_SAMPLE;

      this.mountModals = true;
    },
    triggerEdit(editedCell) {
      const editedObj = {
        colID: editedCell.detail.prop,
        rowID: editedCell.detail.model[this.recordIdColumnName],
        val: editedCell.detail.val
      };
      const indexFound = this.editedCells.findIndex(
        ({ rowID, colID }) =>
          rowID === editedObj.rowID && colID === editedObj.colID
      );

      if (indexFound === -1) {
        this.editedCells.push(editedObj);
      } else {
        this.editedCells[indexFound] = editedObj;
      }
    },
    async applySettings() {
      console.log("Applying settings to revoGrid now.");
      const revoGrid = document.querySelector("revo-grid");
      if (revoGrid) {
        console.log("Refreshing the view now");
        const sort = this.settings?.sortSettings;
        await revoGrid.updateColumnSorting(sort.column, sort.index, sort.order, sort.additive); // sort.index is undefined, not usable atm
      } else {
        console.error('RevoGrid component not found');
      }
    },
    async delay(ms) {
      return new Promise((resolve) => {
          setTimeout(() => resolve(), ms);
        });
    },
    async getDataForTable(datafileID) {
      let dataForTable = await WorksheetService.getWorksheet(datafileID);
      this.settings = dataForTable.settings;
      this.numberOfIncludedDataRecords = 0;
      // set Number Of Included Data Records
      for (const key in dataForTable.worksheet) {
        if (dataForTable.worksheet.hasOwnProperty(key)) {
          if (dataForTable.worksheet[key].Include)
            this.numberOfIncludedDataRecords++;
        }
      }

      this.allColumns = [...dataForTable.headerRow];
      let rowsRevamped = [];
      const worksheetEntries = Object.entries(dataForTable.worksheet);
      worksheetEntries.sort((a, b) => a[1].order - b[1].order);
      for (const [key, rowOriginal] of worksheetEntries) {
        let rowRevamped = {};
        for (let key of Object.keys(rowOriginal)) {
          let decodedKey = decodeKey(key);
          rowRevamped[decodedKey] = rowOriginal[key];
        }
        rowRevamped["select"] = false;
        rowsRevamped.push(rowRevamped);
      }
      this.rows = rowsRevamped;
      this.filteredRows = rowsRevamped;
      if (this.allColumns) {
        await this.setValuesForWorksheet(this.allColumns);
        // this.$nextTick(async () => {
        //   await this.delay(2000)
        //   await this.applySettings();
        // });
      }
    },
    setValuesForWorksheet(allColumns) {
      const sortFun = function (prop, a, b) {
        if (isEmptyString(b[prop])) return 1;
        if (isEmptyString(a[prop])) return -1;
        return a[prop] - b[prop];
      };
      const sortFunBool = function (prop, a, b) {
        if ((a[prop] && b[prop]) || (!a[prop] && !b[prop])) return 0;
        if (a[prop] && !b[prop]) return 1;
        if (!a[prop] && b[prop]) return -1;
      };
      const sortFunCategory = function (prop, a, b) {
        // return 1, a comes after b
        // return 2, a comes before b
        if (isEmptyString(b[prop])) return -1;
        if (isEmptyString(a[prop])) return 1;
        return mapOESNamesToCodes(a[prop]) - mapOESNamesToCodes(b[prop]);
      };
      const sortFunDate = function (prop, a, b) {
        if (isEmptyString(b[prop])) return 1;
        if (isEmptyString(a[prop])) return -1;
        return Date.parse(a[prop]) - Date.parse(b[prop]);
      }
      this.columns = [];
      let recordIdColumnName = "ID";
      allColumns.forEach((column) => {
        if (column.isRecordIdColumn) recordIdColumnName = column.name;
        if (!column.hide) {
          if (column.isCustomSort === "numeric") column.cellCompare = sortFun;
          if (column.isCustomSort === "boolean")
            column.cellCompare = sortFunBool;
          if (column.isCustomSort === "category")
            column.cellCompare = sortFunCategory;
          if (column.isCustomSort === "date")
            column.cellCompare = sortFunDate;
          if (column.prop === "Include") {
            column.cellTemplate = VGridVueTemplate(isExcludedTemplate);
            column.pin = "colPinStart";
          }
          if (column.prop === "Mark") {
            column.cellTemplate = VGridVueTemplate(isMarkedTemplate);
            column.cellTemplate = VGridVueTemplate(isMarkedTemplate);
            column.pin = "colPinStart";
            column.size = 100;
          }

          if (column.prop.includes(DatapointProperty.CATEGORY + ":")) {
            column.size = 250;
            column.cellTemplate = VGridVueTemplate(CategoryTemplate);
          }

          if (column.prop.includes(DatapointProperty.LANGUAGE + ":")) {
            column.size = 200;
          }

          if(column.isAddedByRedem) {
            column.columnProperties = ()=> {
              return {
                style: {
                  background: '#E0FFE3'
                }
              }
            }
          }

          this.columns.push(column);
        }

        // Map IBS into GQS
        if (column.isAddedByRedem && column.name.includes("IBS")) column.name = column.name.replace("IBS", "GQS");
        if (column.isAddedByRedem && column.prop.includes("IBS")) column.prop = column.prop.replace("IBS", "GQS");
      });
      this.recordIdColumnName = recordIdColumnName;

      // add info icon column by creating new column object
      const infoColumn = {
        prop: "info",
        name: "",
        pin: "colPinStart",
        size: 50
      };

      infoColumn.cellTemplate = VGridVueTemplate(infoTemplate, {
        recordIdColumnName
      });
      this.columns.unshift(infoColumn);

      // add select checkbox column by creating new column
      if (this.isLiveCleaning) {
        const selectColumn = {
          prop: "select",
          name: "",
          pin: "colPinStart",
          size: 70
        };
        selectColumn.columnTemplate = VGridVueTemplate(selectAllTemplate);
        selectColumn.cellTemplate = VGridVueTemplate(isSelectTemplate, {
          recordIdColumnName
        });
        this.columns.unshift(selectColumn);
      }

      this.isLoading = false;
    },
    searchWithKey(key) {
      //write search logic only if there are atleast 2 keys
      if (key.length > 0) {
        const tempRows = this.rows.filter((row) => {
          const cells = Object.values(row);
          const compCells = cells.map((cell) => String(cell).toUpperCase());
          let includesSearchTerm = false;
          for (const cell of compCells) {
            if (cell.includes(key.toUpperCase())) {
              includesSearchTerm = true;
            }
          }
          return includesSearchTerm;
        });
        this.filteredRows = tempRows;
      } else {
        this.filteredRows = this.rows;
      }
    },
    clickManageColumns() {
      this.showManageColumnsModal = true;
    },
    reloadPage() {
      window.location.reload();
    },
    getColumnsFromManageColumn(columns) {
      this.allColumns = columns;
      this.setValuesForWorksheet(this.allColumns);
      this.showManageColumnsModal = false;
    },
    clickApply() {
      this.showApplyEditsModal = true;
    },
    clickWeightingData() {
      this.isDisplayWeightDataModal = true;
    },
    clickCleanData() {
      this.isDisplayCleanDataModal = true;
    },
    async clickExportWorksheet() {
      this.isDisplayExportWorksheetModal = true;
    },
    closeCleanDataModal() {
      this.isDisplayCleanDataModal = false;
    },
    closeWeightDataModal() {
      this.isDisplayWeightDataModal = false;
    },
    closeExportWorksheetModal() {
      this.isDisplayExportWorksheetModal = false;
    },
    async commitCleanData() {
      this.isDisplayCleanDataModal = false;
    },
    clickUpdateDatafile() {
      this.isDisplayUpdateDatafileModal = true;
    },
    clickQuotaSettings() {
      this.isDisplayQuotaSettingsModal = true;
      EventBus.$emit("showQuotaSettingsModal");
    },
    commitExportWorksheet(event) {
      this.exportWorksheet(event, this);
    },
    suggestScoreFromRespondent(respondentId) {
      setTimeout(() => {
        this.isDisplayOESFeedbackModal = false;
        // Reload data records
        this.openRespondentModal(respondentId);
      }, 500);
    },
    openRespondentModal(respondentId) {
      this.showOESTab = true;
      this.showDetailsForRecord = respondentId;
      this.activatedtabInRespondentModal = 1;

      this.showRespondentModal = true;
    },
    clickDeleteRespondents() {
      this.isDisplayDeleteRespondentModal = true;
    },
    onBeforeSorting(event) {
      this.settings.sortSettings = event.detail;
    },
    onBeforeFilterApply(event) {
      this.settings.filterSettings = event.detail;
    }
  }
};
</script>

<template>
  <Layout>
    <!-- Results Row -->
    <div class="row" v-if="this.isLoading || dbDatafile.numberOfRecords > 0">
      <div class="col-12 mt-3">
        <div class="card">
          <div class="card-body">
            <!-- Page Loader -->
            <div v-if="this.isLoading">
              <div class="row justify-content-center">
                <div class="col-6 text-center p-5">
                  <b-spinner
                    style="width: 5rem; height: 5rem"
                    class="m-2"
                    variant="mid"
                    role="status"
                  ></b-spinner>
                </div>
              </div>
            </div>

            <!-- Worksheet -->
            <div v-if="!this.isLoading && this.columns.length > 0">
              <!-- Header -->
              <div class="row mb-3">
                <div class="col-md-4">
                  <h5>{{ datafileTitle }}</h5>
                  <label>
                    {{ $t("worksheet.general.numberOfIncludedDataRecords") }} :
                  </label>
                  <b-badge
                    class="ml-2 badge-soft-success"
                    style="font-size: 15px"
                  >
                    <strong> {{ this.numberOfIncludedDataRecords }} </strong>
                  </b-badge>
                </div>

                <div class="col-md-8 text-right">
                  <!-- Export -->
                  <span
                    @click="clickExportWorksheet()"
                    class="text-primary font-weight-medium mr-3"
                    style="cursor: pointer"
                    ><i class="bx bx-export"></i>
                    {{ $t("datafileResultGeneral.buttonRow.exportButton") }}
                  </span>
                  <!-- Cleaning -->
                  <span
                    @click="clickCleanData()"
                    class="text-primary font-weight-medium mr-3"
                    style="cursor: pointer"
                  >
                    <i class="bx bx-brush"></i>
                    {{
                      $t(
                        "datafileResultGeneral.buttonRow.cleaningAndMarkButton"
                      )
                    }}
                  </span>
                  <!-- Other Options  -->
                  <b-dropdown
                    toggle-class="nav-btn"
                    size="sm"
                    right
                    variant="light"
                  >
                    <template v-slot:button-content>
                      <i class="bx bx-dots-horizontal-rounded font-size-15"></i>
                    </template>
                    <b-dropdown-item
                      v-if="
                        !this.isLiveCleaning &&
                        loggedInUser.company.contractStatus === 'RUNNING'
                      "
                      @click="clickUpdateDatafile()"
                      ><i class="bx bx-reset mr-2"></i
                      >{{
                        $t("datafileResultGeneral.buttonRow.updateDatafile")
                      }}</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="clickQuotaSettings()"
                      v-if="!this.isLiveCleaning"
                      ><i class="bx bxs-pie-chart-alt-2 mr-2"></i
                      >{{
                        $t("datafileResultGeneral.buttonRow.quotaSettings")
                      }}</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="this.isLiveCleaning === 'STOPPED'"
                      @click="clickWeightingData()"
                    >
                      <i class="bx bx-slider mr-2"></i>
                      {{
                        $t("datafileResultGeneral.buttonRow.weightingButton")
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>

              <!-- Table Actions -->
              <div class="row mt-3 mb-2">
                <!-- Search bar -->
                <div class="col-md-3">
                  <b-input
                    @input="this.searchWithKey"
                    type="search"
                    :placeholder="
                      this.$i18n.t(
                        'worksheet.manageColumnModal.searchPlaceholder'
                      )
                    "
                    class="form-control"
                  />
                </div>
                <!-- Missing Value Information -->
                <div class="col-md-3 text-right">
                  <div
                    v-if="
                      dbDatafile.metadata.missingValues &&
                      dbDatafile.metadata.missingValues.length > 0
                    "
                  >
                    {{ $t("worksheet.general.missingValues") }} :
                    <b-badge
                      v-for="(missingValue, missingValueIndex) in dbDatafile
                        .metadata.missingValues"
                      :key="missingValueIndex"
                      class="ml-1"
                      style="font-size: 12px"
                      variant="dark"
                    >
                      {{ missingValue }}
                    </b-badge>
                  </div>
                </div>
                <!-- Actions -->
                <div class="col-md-6 text-right">
                  <!-- Show/Hide Columns -->
                  <span
                    @click="clickManageColumns()"
                    class="text-primary font-weight-medium mr-3"
                    style="cursor: pointer"
                  >
                    <i class="bx bx-wrench"></i>
                    {{ $t("worksheet.general.manageColumnButton") }}
                  </span>
                  <!-- Delete Respondents -->
                  <b-button
                    v-if="displayDeleteRespondentButton"
                    variant="danger"
                    @click="clickDeleteRespondents()"
                    size="sm"
                    class="ml-2"
                  >
                    <i class="bx bx-trash mr-1"></i
                    >{{ $t("worksheet.general.deleteRespondentsButton") }}
                  </b-button>
                  <!-- Save Changes Button -->
                  <b-button
                    variant="success"
                    @click="clickApply()"
                    size="sm"
                    class="ml-2"
                  >
                    <i class="bx bxs-check-circle mr-1"></i>
                    {{ $t("worksheet.general.saveButton") }}
                  </b-button>
                </div>
              </div>

              <v-grid
                id="revo-grid"
                ref="revoGrid"
                theme="compact"
                :columns="columns"
                :source="filteredRows"
                :filter="true"
                :resize="true"
                @afteredit="this.triggerEdit"
                @beforesorting="onBeforeSorting"
                @beforefilterapply="onBeforeFilterApply"
                :rowHeaders="true"
                style="height: 60vh"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="col-12 text-center mt-3" style="min-height: 200px">
      <img
        src="@/assets/images/information.png"
        alt
        class="img-fluid mb-4 mt-3"
        style="width: 60px"
      />
      <h5>{{ $t("general.noDatarecords") }}</h5>
    </div>

    <!-- Back navigation checking modal -->
    <BackConfirmModal
      :visible="showConfirmDialog"
      @close="closeDialog"
      @discardChanges="discardChanges"
    />

    <!-- Manage Columns Modal -->
    <ManageColumnsModal
      v-if="showManageColumnsModal === true"
      :visible="showManageColumnsModal"
      :allColumns="this.allColumns"
      @getColumns="getColumnsFromManageColumn"
      @close="showManageColumnsModal = false"
      :isLiveCleaning="isLiveCleaning"
    />

    <!-- Apply Edits Modal -->
    <ApplyEditsModal
      v-if="showApplyEditsModal === true"
      :visible="showApplyEditsModal"
      @close="showApplyEditsModal = false"
      :headerRow="allColumns"
      :worksheetRows="rows"
      :editedCells="editedCells"
      :settings="settings"
      :datafile="datafileId"
      @reload="reloadPage()"
    />

    <!-- Respondent Details Modal --->
    <RespondentModal
      v-if="showRespondentModal === true"
      :visible="showRespondentModal"
      :dataRecord="showDetailsForRecord"
      :datafile="datafileId"
      :activatedTab="activatedtabInRespondentModal"
      @close="showRespondentModal = false"
      :isTimescoreTab="false"
      :includeTimestamp="includeTimestamp"
      :isIBSTab="false"
      :includeIBS="includeIBS"
      :includeCS="includeCS"
      :isCSTab="false"
      :includePS="includePS"
      :isPSTab="false"
      :includeOES="includeOES"
      :isOESTab="showOESTab"
    />

    <!-- Clean Data Modal -->
    <CleanDataModal
      v-if="isDisplayCleanDataModal"
      :visible="(!this.isLiveCleaning || this.isLiveCleaning === 'STOPPED')"
      :datafileId="this.datafileId"
      @close="closeCleanDataModal"
      @reload="reloadPage()"
      @commitCleanData="commitCleanData"
    />

    <!-- Edit Cleaning Settings Modal -->
    <EditCleaningSettingsModal
      v-if="isDisplayCleanDataModal"
      :visible="this.isLiveCleaning === 'RUNNING' && this.isDisplayCleanDataModal"
      @close="isDisplayCleanDataModal = false"
    />

    <!-- Weighting Modal  -->
    <WeightDataModal
      v-if="this.mountModals"
      :visible="this.isDisplayWeightDataModal"
      @reload="reloadPage()"
      :datafileId="this.datafileId"
      @close="closeWeightDataModal"
      :weightingSettings="this.weightingSettings"
    />

    <!-- Export Modal  -->
    <ExportWorksheetModal
      :visible="this.isDisplayExportWorksheetModal"
      :datafileId="this.datafileId"
      :isLiveCleaning="this.isLiveCleaning"
      @close="closeExportWorksheetModal"
      @commitExportWorksheet="commitExportWorksheet"
    />

    <!-- Update Datafile Modal  -->
    <UpdateDatafileModal
      :visible="this.isDisplayUpdateDatafileModal"
      :datafileId="this.datafileId"
      :oldDatafileKey="this.datafileKey"
      :metadata="this.metadata"
      @reload="reloadPage()"
      @close="isDisplayUpdateDatafileModal = false"
    />

    <!-- Quota Settings Modal -->
    <QuotaSettingsModal
      :visible="this.isDisplayQuotaSettingsModal"
      :datafileId="this.datafileId"
      :datafileTitle="this.datafileTitle"
      :metadata="this.metadata"
      @reload="reloadPage()"
      @close="isDisplayQuotaSettingsModal = false"
    />

    <!-- OES Feedback Modal -->
    <OESFeedbackModal
      v-if="isDisplayOESFeedbackModal"
      :visible="isDisplayOESFeedbackModal"
      @close="isDisplayOESFeedbackModal = false"
      @suggestAndClose="finalizeSuggestScore"
      @suggestScoreFromRespondent="suggestScoreFromRespondent"
      :datafileId="this.datafileId"
      :dataPointIndentifier="this.dataPointIdentifierForFeedback"
      :questionTitle="this.questionTitleForFeedback"
      :selectedDataRecord="this.selectedDataRecordForFeedback"
      :openFromRespondentModal="this.openOESFeedbackModalFromRespondentModal"
    />

    <!-- Delete Selected Respondents Modal -->
    <DeleteSelectedRespondentsModal
      v-if="isDisplayDeleteRespondentModal"
      :visible="isDisplayDeleteRespondentModal"
      @close="isDisplayDeleteRespondentModal = false"
      @reload="reloadPage()"
      :datafileId="this.datafileId"
      :respondentsToDelete="this.selectedRespondentsForDelete"
    />
  </Layout>
</template>
