var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        !_vm.isLoading && !_vm.isSuccess
          ? _c("div", [
              _c("div", { staticClass: "row mt-2 text-center" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("worksheet.saveSettingsModal.title"))),
                  ]),
                  _c("p", { staticClass: "mt-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("worksheet.saveSettingsModal.description")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mt-3",
                        attrs: { variant: "danger", dismissible: "" },
                        model: {
                          value: _vm.isError,
                          callback: function ($$v) {
                            _vm.isError = $$v
                          },
                          expression: "isError",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("worksheet.saveSettingsModal.errorAlert")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mt-3 text-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.clickSave()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-save" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("worksheet.saveSettingsModal.saveButton")
                            )
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCancel()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-x" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("worksheet.saveSettingsModal.cancelButton")
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.isLoading && !_vm.isSuccess
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-center mt-3" }, [
                  _c("h5", { staticClass: "text-cess" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("worksheet.saveSettingsModal.loading.title")
                        ) +
                        " "
                    ),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "worksheet.saveSettingsModal.loading.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        !_vm.isLoading && _vm.isSuccess
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("i", {
                    staticClass:
                      "bx bx-check-circle bx-tada text-success display-1",
                  }),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-center mt-3" }, [
                  _c("h5", { staticClass: "text-cess" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("worksheet.saveSettingsModal.success.title")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 mt-4 text-center" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCancel()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-x" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "worksheet.saveSettingsModal.success.continueButton"
                              )
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }