var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "row navbar-header" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "pl-5" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/Redem-Logo.png"),
              alt: "",
              height: "30",
            },
            on: {
              click: function ($event) {
                return _vm.clickLogo()
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c(
          "div",
          { staticClass: "pr-4" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "light" },
                on: {
                  click: function ($event) {
                    return _vm.clickBack()
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("worksheet.general.backButton")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }