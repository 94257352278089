var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      !_vm.isLoading && _vm.isError
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "danger" },
                    model: {
                      value: _vm.isError,
                      callback: function ($$v) {
                        _vm.isError = $$v
                      },
                      expression: "isError",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("worksheet.deleteRespondentsModal.errorAlert")
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading && !_vm.isSuccess
        ? _c("div", { staticClass: "mt-3 mb-3 text-center" }, [
            _c("div", { staticClass: "col-md-12 mb-2" }, [
              _c("img", {
                staticClass: "img-fluid mb-4 mt-3",
                staticStyle: { width: "80px" },
                attrs: {
                  src: require("@/assets/images/delete-datafile.png"),
                  alt: "",
                },
              }),
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.$t("worksheet.deleteRespondentsModal.title"))
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-md-12 mt-3" }, [
              _c("p", [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.numberOfRespondentsToDelete) +
                      " " +
                      _vm._s(
                        _vm.$t(
                          "worksheet.deleteRespondentsModal.InfoMessage.part1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "worksheet.deleteRespondentsModal.description.part1"
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "worksheet.deleteRespondentsModal.description.part2"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-12 mt-4" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.clickDeleteButton()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "worksheet.deleteRespondentsModal.buttons.delete"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCancelButton()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "worksheet.deleteRespondentsModal.buttons.cancel"
                        )
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading && _vm.isSuccess
        ? _c("div", { staticClass: "mt-3 mb-3 text-center" }, [
            _c("div", { staticClass: "col-md-12 mb-2" }, [
              _c("i", {
                staticClass:
                  "bx bx-check-circle bx-tada text-success display-1",
              }),
              _c("h5", { staticClass: "mt-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.numberOfRespondentsToDelete) +
                    " " +
                    _vm._s(
                      _vm.$t(
                        "worksheet.deleteRespondentsModal.successMessage.part1"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-12 mt-4" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCloseButton()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("worksheet.deleteRespondentsModal.buttons.close")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }