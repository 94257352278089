<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {
      /******** Data Variables ******/
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickDiscard() {
      this.$emit("discardChanges");
    },
    clickClose() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="p-2">
      <!-- Header -->
      <div class="row mt-2 text-center">
        <div class="col-12">
          <h5>{{ $t("worksheet.discardModal.title") }}</h5>
          <p class="mt-3">
            {{ $t("worksheet.discardModal.text") }}
          </p>
        </div>
      </div>

      <!-- Actions -->
      <div class="row mt-3 text-center">
        <div class="col-12">
          <b-button @click="clickDiscard()" variant="warning" class="mr-2"
            ><i class="bx bx-save"></i>
            {{ $t("worksheet.discardModal.confirmButton") }}</b-button
          >
          <b-button @click="clickClose()" variant="light"
            ><i class="bx bx-x"></i>
            {{ $t("worksheet.discardModal.cancelButton") }}</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>
