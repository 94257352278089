var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.model[_vm.prop]
      ? _c("span", [_vm._v(" - ")])
      : _c(
          "span",
          {
            staticClass: "badge badge-silver font-size-12",
            class: {
              "oes-answer-cat-va": `${_vm.model[_vm.prop]}` == `Valid Answer`,
              "oes-answer-cat-ga": `${_vm.model[_vm.prop]}` == `Generic Answer`,
              "oes-answer-cat-ni": `${_vm.model[_vm.prop]}` == `No Information`,
              "oes-answer-cat-pr": `${_vm.model[_vm.prop]}` == `Bad Language`,
              "oes-answer-cat-no": `${_vm.model[_vm.prop]}` == `Nonsense`,
              "oes-answer-cat-dr":
                `${_vm.model[_vm.prop]}` == `Duplicate Respondent`,
              "oes-answer-cat-da":
                `${_vm.model[_vm.prop]}` == `Duplicate Answer`,
              "oes-answer-cat-wt": `${_vm.model[_vm.prop]}` == `Wrong Topic`,
              "oes-answer-cat-wl": `${_vm.model[_vm.prop]}` == `Wrong Language`,
              "oes-answer-cat-cp": `${_vm.model[_vm.prop]}` == `Copy Paste`,
              "oes-answer-cat-ar": `${_vm.model[_vm.prop]}` == `Fake Answer`,
              "oes-answer-cat-ai-error": `${_vm.model[_vm.prop]}` == `AI Error`,
            },
          },
          [_vm._v(" " + _vm._s(_vm.model[_vm.prop]) + " ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }