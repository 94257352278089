var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-form-checkbox", {
        staticClass: "mt-2",
        attrs: { name: "check-button", switch: "" },
        on: {
          change: function ($event) {
            return _vm.changeStatus()
          },
        },
        model: {
          value: _vm.isIncluded,
          callback: function ($$v) {
            _vm.isIncluded = $$v
          },
          expression: "isIncluded",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }