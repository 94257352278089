var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("i", {
      staticClass: "bx bxs-info-circle text-info",
      staticStyle: { cursor: "pointer" },
      on: {
        click: function ($event) {
          return _vm.clickOnInfo()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }