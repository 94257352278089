<script>
export default {
  components: {},
  data() {
    return {
      datafileId: null
    };
  },
  mounted() {
    this.datafileId = this.$route.query.datafileId;
  },
  methods: {
    clickLogo() {
      this.$router.push("/datafiles");
    },
    clickBack() {
      this.$router.push(`/datafile-result?datafileId=${this.datafileId}`);
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="row navbar-header">
      <div class="col-6">
        <!-- LOGO -->
        <div class="pl-5">
          <img
            src="@/assets/images/Redem-Logo.png"
            alt
            height="30"
            @click="clickLogo()"
          />
        </div>
      </div>

      <div class="col-6 text-right">
        <div class="pr-4">
          <b-button variant="light" @click="clickBack()">
            {{ $t("worksheet.general.backButton") }}
          </b-button>
        </div>
      </div>
    </div>
  </header>
</template>
