var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      this.isLoading || _vm.dbDatafile.numberOfRecords > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mt-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  this.isLoading
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-6 text-center p-5" },
                              [
                                _c("b-spinner", {
                                  staticClass: "m-2",
                                  staticStyle: {
                                    width: "5rem",
                                    height: "5rem",
                                  },
                                  attrs: { variant: "mid", role: "status" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  !this.isLoading && this.columns.length > 0
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "row mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("h5", [_vm._v(_vm._s(_vm.datafileTitle))]),
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "worksheet.general.numberOfIncludedDataRecords"
                                        )
                                      ) +
                                      " : "
                                  ),
                                ]),
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "ml-2 badge-soft-success",
                                    staticStyle: { "font-size": "15px" },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.numberOfIncludedDataRecords
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-8 text-right" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-primary font-weight-medium mr-3",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickExportWorksheet()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "bx bx-export" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.buttonRow.exportButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-primary font-weight-medium mr-3",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickCleanData()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "bx bx-brush" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.buttonRow.cleaningAndMarkButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: {
                                      "toggle-class": "nav-btn",
                                      size: "sm",
                                      right: "",
                                      variant: "light",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c("i", {
                                                staticClass:
                                                  "bx bx-dots-horizontal-rounded font-size-15",
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2610545339
                                    ),
                                  },
                                  [
                                    !this.isLiveCleaning &&
                                    _vm.loggedInUser.company.contractStatus ===
                                      "RUNNING"
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickUpdateDatafile()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-reset mr-2",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultGeneral.buttonRow.updateDatafile"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !this.isLiveCleaning
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickQuotaSettings()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bx bxs-pie-chart-alt-2 mr-2",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultGeneral.buttonRow.quotaSettings"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    this.isLiveCleaning === "STOPPED"
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickWeightingData()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-slider mr-2",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "datafileResultGeneral.buttonRow.weightingButton"
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row mt-3 mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("b-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "search",
                                    placeholder: this.$i18n.t(
                                      "worksheet.manageColumnModal.searchPlaceholder"
                                    ),
                                  },
                                  on: { input: this.searchWithKey },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-3 text-right" }, [
                              _vm.dbDatafile.metadata.missingValues &&
                              _vm.dbDatafile.metadata.missingValues.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "worksheet.general.missingValues"
                                            )
                                          ) +
                                          " : "
                                      ),
                                      _vm._l(
                                        _vm.dbDatafile.metadata.missingValues,
                                        function (
                                          missingValue,
                                          missingValueIndex
                                        ) {
                                          return _c(
                                            "b-badge",
                                            {
                                              key: missingValueIndex,
                                              staticClass: "ml-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { variant: "dark" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(missingValue) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6 text-right" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-primary font-weight-medium mr-3",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickManageColumns()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "bx bx-wrench" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "worksheet.general.manageColumnButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.displayDeleteRespondentButton
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          variant: "danger",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDeleteRespondents()
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bx bx-trash mr-1",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "worksheet.general.deleteRespondentsButton"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { variant: "success", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickApply()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bx bxs-check-circle mr-1",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("worksheet.general.saveButton")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-grid", {
                            ref: "revoGrid",
                            staticStyle: { height: "60vh" },
                            attrs: {
                              id: "revo-grid",
                              theme: "compact",
                              columns: _vm.columns,
                              source: _vm.filteredRows,
                              filter: true,
                              resize: true,
                              rowHeaders: true,
                            },
                            on: {
                              afteredit: this.triggerEdit,
                              beforesorting: _vm.onBeforeSorting,
                              beforefilterapply: _vm.onBeforeFilterApply,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _c(
            "div",
            {
              staticClass: "col-12 text-center mt-3",
              staticStyle: { "min-height": "200px" },
            },
            [
              _c("img", {
                staticClass: "img-fluid mb-4 mt-3",
                staticStyle: { width: "60px" },
                attrs: {
                  src: require("@/assets/images/information.png"),
                  alt: "",
                },
              }),
              _c("h5", [_vm._v(_vm._s(_vm.$t("general.noDatarecords")))]),
            ]
          ),
      _c("BackConfirmModal", {
        attrs: { visible: _vm.showConfirmDialog },
        on: { close: _vm.closeDialog, discardChanges: _vm.discardChanges },
      }),
      _vm.showManageColumnsModal === true
        ? _c("ManageColumnsModal", {
            attrs: {
              visible: _vm.showManageColumnsModal,
              allColumns: this.allColumns,
              isLiveCleaning: _vm.isLiveCleaning,
            },
            on: {
              getColumns: _vm.getColumnsFromManageColumn,
              close: function ($event) {
                _vm.showManageColumnsModal = false
              },
            },
          })
        : _vm._e(),
      _vm.showApplyEditsModal === true
        ? _c("ApplyEditsModal", {
            attrs: {
              visible: _vm.showApplyEditsModal,
              headerRow: _vm.allColumns,
              worksheetRows: _vm.rows,
              editedCells: _vm.editedCells,
              settings: _vm.settings,
              datafile: _vm.datafileId,
            },
            on: {
              close: function ($event) {
                _vm.showApplyEditsModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.showRespondentModal === true
        ? _c("RespondentModal", {
            attrs: {
              visible: _vm.showRespondentModal,
              dataRecord: _vm.showDetailsForRecord,
              datafile: _vm.datafileId,
              activatedTab: _vm.activatedtabInRespondentModal,
              isTimescoreTab: false,
              includeTimestamp: _vm.includeTimestamp,
              isIBSTab: false,
              includeIBS: _vm.includeIBS,
              includeCS: _vm.includeCS,
              isCSTab: false,
              includePS: _vm.includePS,
              isPSTab: false,
              includeOES: _vm.includeOES,
              isOESTab: _vm.showOESTab,
            },
            on: {
              close: function ($event) {
                _vm.showRespondentModal = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayCleanDataModal
        ? _c("CleanDataModal", {
            attrs: {
              visible:
                !this.isLiveCleaning || this.isLiveCleaning === "STOPPED",
              datafileId: this.datafileId,
            },
            on: {
              close: _vm.closeCleanDataModal,
              reload: function ($event) {
                return _vm.reloadPage()
              },
              commitCleanData: _vm.commitCleanData,
            },
          })
        : _vm._e(),
      _vm.isDisplayCleanDataModal
        ? _c("EditCleaningSettingsModal", {
            attrs: {
              visible:
                this.isLiveCleaning === "RUNNING" &&
                this.isDisplayCleanDataModal,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayCleanDataModal = false
              },
            },
          })
        : _vm._e(),
      this.mountModals
        ? _c("WeightDataModal", {
            attrs: {
              visible: this.isDisplayWeightDataModal,
              datafileId: this.datafileId,
              weightingSettings: this.weightingSettings,
            },
            on: {
              reload: function ($event) {
                return _vm.reloadPage()
              },
              close: _vm.closeWeightDataModal,
            },
          })
        : _vm._e(),
      _c("ExportWorksheetModal", {
        attrs: {
          visible: this.isDisplayExportWorksheetModal,
          datafileId: this.datafileId,
          isLiveCleaning: this.isLiveCleaning,
        },
        on: {
          close: _vm.closeExportWorksheetModal,
          commitExportWorksheet: _vm.commitExportWorksheet,
        },
      }),
      _c("UpdateDatafileModal", {
        attrs: {
          visible: this.isDisplayUpdateDatafileModal,
          datafileId: this.datafileId,
          oldDatafileKey: this.datafileKey,
          metadata: this.metadata,
        },
        on: {
          reload: function ($event) {
            return _vm.reloadPage()
          },
          close: function ($event) {
            _vm.isDisplayUpdateDatafileModal = false
          },
        },
      }),
      _c("QuotaSettingsModal", {
        attrs: {
          visible: this.isDisplayQuotaSettingsModal,
          datafileId: this.datafileId,
          datafileTitle: this.datafileTitle,
          metadata: this.metadata,
        },
        on: {
          reload: function ($event) {
            return _vm.reloadPage()
          },
          close: function ($event) {
            _vm.isDisplayQuotaSettingsModal = false
          },
        },
      }),
      _vm.isDisplayOESFeedbackModal
        ? _c("OESFeedbackModal", {
            attrs: {
              visible: _vm.isDisplayOESFeedbackModal,
              datafileId: this.datafileId,
              dataPointIndentifier: this.dataPointIdentifierForFeedback,
              questionTitle: this.questionTitleForFeedback,
              selectedDataRecord: this.selectedDataRecordForFeedback,
              openFromRespondentModal:
                this.openOESFeedbackModalFromRespondentModal,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayOESFeedbackModal = false
              },
              suggestAndClose: _vm.finalizeSuggestScore,
              suggestScoreFromRespondent: _vm.suggestScoreFromRespondent,
            },
          })
        : _vm._e(),
      _vm.isDisplayDeleteRespondentModal
        ? _c("DeleteSelectedRespondentsModal", {
            attrs: {
              visible: _vm.isDisplayDeleteRespondentModal,
              datafileId: this.datafileId,
              respondentsToDelete: this.selectedRespondentsForDelete,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayDeleteRespondentModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }