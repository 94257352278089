var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        scrollable: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "xl",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("h5", [
              _c("i", { staticClass: "bx bx-wrench" }),
              _vm._v(
                " " + _vm._s(_vm.$t("worksheet.manageColumnModal.title")) + " "
              ),
            ]),
            _c("p", { staticClass: "mt-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("worksheet.manageColumnModal.text")) + " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row align-items-center mb-4 mt-3" }, [
          _c("div", { staticClass: "col-md-1" }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("worksheet.manageColumnModal.search")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("b-input", {
                staticClass: "form-control",
                attrs: {
                  type: "search",
                  placeholder: this.$i18n.t(
                    "worksheet.manageColumnModal.searchPlaceholder"
                  ),
                },
                on: { input: this.searchWithKey },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticStyle: { border: "1px solid #d9d9d9", height: "400px" } },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "label",
                    { staticClass: "d-inline-flex align-items-center" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("worksheet.manageColumnModal.category1")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            on: { change: _vm.selectAllRedemColumns },
                            model: {
                              value: _vm.showAllRedemColumns,
                              callback: function ($$v) {
                                _vm.showAllRedemColumns = $$v
                              },
                              expression: "showAllRedemColumns",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "worksheet.manageColumnModal.showAllRedemColumns"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "simplebar",
                          { staticStyle: { "max-height": "280px" } },
                          _vm._l(
                            this.redemColumns,
                            function (redemColumn, columnIndex) {
                              return _c(
                                "div",
                                { key: columnIndex, staticClass: "mb-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: redemColumn.hide,
                                            expression: "redemColumn.hide",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: columnIndex,
                                          "true-value": false,
                                          disabled: _vm.isDefaultHiddenColumn(
                                            redemColumn.prop
                                          ),
                                          "false-value": true,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            redemColumn.hide
                                          )
                                            ? _vm._i(redemColumn.hide, null) >
                                              -1
                                            : _vm._q(redemColumn.hide, false),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = redemColumn.hide,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? false
                                                  : true
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      redemColumn,
                                                      "hide",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      redemColumn,
                                                      "hide",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  redemColumn,
                                                  "hide",
                                                  $$c
                                                )
                                              }
                                            },
                                            function ($event) {
                                              return _vm.changeHideValueOfRedemColumn(
                                                columnIndex
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-control-label",
                                          attrs: { for: columnIndex },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-weight-normal",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(redemColumn.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticStyle: { border: "1px solid #d9d9d9", height: "400px" } },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "label",
                    { staticClass: "d-inline-flex align-items-center" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("worksheet.manageColumnModal.category2")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            on: { change: _vm.selectAllOtherColumns },
                            model: {
                              value: _vm.showAllOtherColumns,
                              callback: function ($$v) {
                                _vm.showAllOtherColumns = $$v
                              },
                              expression: "showAllOtherColumns",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "worksheet.manageColumnModal.showAllOtherColumns"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        !_vm.isLiveCleaning
                          ? _c(
                              "b-form-checkbox",
                              {
                                attrs: { disabled: _vm.showAllOtherColumns },
                                on: { change: _vm.selectAllSelectedColumns },
                                model: {
                                  value: _vm.showAllSelectedColumns,
                                  callback: function ($$v) {
                                    _vm.showAllSelectedColumns = $$v
                                  },
                                  expression: "showAllSelectedColumns",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "worksheet.manageColumnModal.showAllSelectedColumns"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "simplebar",
                          {
                            staticStyle: {
                              "max-height": "280px",
                              "overflow-x": "hidden",
                            },
                          },
                          _vm._l(
                            this.otherColumns,
                            function (otherColumn, columnIndex) {
                              return _c(
                                "div",
                                {
                                  key: otherColumn.name + columnIndex,
                                  staticClass: "row mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            value: false,
                                            disabled: _vm.isDefaultHiddenColumn(
                                              otherColumn.prop
                                            ),
                                            "unchecked-value": true,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeHideValueOfOtherColumn(
                                                columnIndex
                                              )
                                            },
                                          },
                                          model: {
                                            value: otherColumn.hide,
                                            callback: function ($$v) {
                                              _vm.$set(otherColumn, "hide", $$v)
                                            },
                                            expression: "otherColumn.hide",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(otherColumn.name) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5" },
                                    [
                                      otherColumn.readonly
                                        ? _c(
                                            "b-badge",
                                            { attrs: { variant: "info" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "worksheet.manageColumnModal.selectedColumnLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "row mt-4 mb-2" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.clickSave()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-save" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("worksheet.manageColumnModal.saveButton"))
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.clickCancel()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-x" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("worksheet.manageColumnModal.cancelButton"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }