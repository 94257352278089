<script>
export default {
  props: ["rowIndex", "prop", "model"],
  name: "isMarkedTemplte",
  data() {
    return {};
  },
  methods: {
    markedRespondent() {
      this.model[this.prop] = true;
    },
    unmarkedRespondent() {
      this.model[this.prop] = false;
    }
  }
};
</script>

<template>
  <div>
    <i
      v-if="!model[prop]"
      class="bx bxs-star text-light"
      @click="markedRespondent()"
      style="cursor:pointer"
    ></i
    ><i
      v-else
      class="bx bxs-star text-danger"
      @click="unmarkedRespondent()"
      style="cursor:pointer"
    ></i>
  </div>
</template>
