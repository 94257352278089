<script>
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  name: "isSelectAllTemplete",
  data() {
    return {
      selectAll: false
    };
  },
  created() {
    EventBus.$on("removeSelectAll", () => {
      this.selectAll = false;
    });
  },
  methods: {
    changeStatus() {
      if (this.selectAll) EventBus.$emit("selectedAll");
      else EventBus.$emit("unSelectedAll");
    }
  }
};
</script>

<template>
  <div>
    <b-form-checkbox
      v-model="selectAll"
      name="check-button"
      class="mt-2"
      @change="changeStatus()"
    >
    </b-form-checkbox>
  </div>
</template>
