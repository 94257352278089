var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "row mt-2 text-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t("worksheet.discardModal.title")))]),
            _c("p", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("worksheet.discardModal.text")) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row mt-3 text-center" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.clickDiscard()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-save" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("worksheet.discardModal.confirmButton"))
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.clickClose()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-x" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("worksheet.discardModal.cancelButton"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }