<script>
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: ["rowIndex", "prop", "model"],
  name: "isSelectTemplete",
  data() {
    return {};
  },
  methods: {
    changeStatus() {
      if (this.model[this.prop]) EventBus.$emit("selected", this.model);
      else EventBus.$emit("unSelected", this.model);
    }
  }
};
</script>

<template>
  <div>
    <b-form-checkbox
      v-model="model[prop]"
      name="check-button"
      class="mt-2"
      @change="changeStatus()"
    >
    </b-form-checkbox>
  </div>
</template>
