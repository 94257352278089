<script>
import WorksheetTopbar from "@/shared/components/worksheet-topbar";

export default {
  components: {
    WorksheetTopbar
  },
  methods: {}
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <WorksheetTopbar />

      <div class="pl-4 pr-4">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
