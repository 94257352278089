var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.model[_vm.prop]
      ? _c("i", {
          staticClass: "bx bxs-star text-light",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.markedRespondent()
            },
          },
        })
      : _c("i", {
          staticClass: "bx bxs-star text-danger",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.unmarkedRespondent()
            },
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }